import { createPlugin } from "~/ecs/base";
import CorePlugin from "~/ecs/plugins/core";
import PerspectivePlugin from "~/ecs/plugins/perspective";

import * as Components from "./components";
import * as Systems from "./systems";

export * from "./components";

export { Components };

export default createPlugin({
  name: "lighting",
  plugins: [CorePlugin, PerspectivePlugin],
  systems: Object.values(Systems),
  components: Object.values(Components),
});
