import { TextureLoader } from "three";

import * as T from "./texture-assets";

const loader = new TextureLoader();

export const textures = {
  circle_01: () => loader.load(T.circle_01),
  circle_02: () => loader.load(T.circle_02),
  circle_03: () => loader.load(T.circle_03),
  circle_04: () => loader.load(T.circle_04),
  circle_05: () => loader.load(T.circle_05),
  dirt_01: () => loader.load(T.dirt_01),
  dirt_02: () => loader.load(T.dirt_02),
  dirt_03: () => loader.load(T.dirt_03),
  fire_01: () => loader.load(T.fire_01),
  fire_02: () => loader.load(T.fire_02),
  flame_01: () => loader.load(T.flame_01),
  flame_02: () => loader.load(T.flame_02),
  flame_03: () => loader.load(T.flame_03),
  flame_04: () => loader.load(T.flame_04),
  flame_05: () => loader.load(T.flame_05),
  flame_06: () => loader.load(T.flame_06),
  flare_01: () => loader.load(T.flare_01),
  light_01: () => loader.load(T.light_01),
  light_02: () => loader.load(T.light_02),
  light_03: () => loader.load(T.light_03),
  magic_01: () => loader.load(T.magic_01),
  magic_02: () => loader.load(T.magic_02),
  magic_03: () => loader.load(T.magic_03),
  magic_04: () => loader.load(T.magic_04),
  magic_05: () => loader.load(T.magic_05),
  muzzle_01: () => loader.load(T.muzzle_01),
  muzzle_02: () => loader.load(T.muzzle_02),
  muzzle_03: () => loader.load(T.muzzle_03),
  muzzle_04: () => loader.load(T.muzzle_04),
  muzzle_05: () => loader.load(T.muzzle_05),
  scorch_01: () => loader.load(T.scorch_01),
  scorch_02: () => loader.load(T.scorch_02),
  scorch_03: () => loader.load(T.scorch_03),
  scratch_01: () => loader.load(T.scratch_01),
  slash_01: () => loader.load(T.slash_01),
  slash_02: () => loader.load(T.slash_02),
  slash_03: () => loader.load(T.slash_03),
  slash_04: () => loader.load(T.slash_04),
  smoke_01: () => loader.load(T.smoke_01),
  smoke_02: () => loader.load(T.smoke_02),
  smoke_03: () => loader.load(T.smoke_03),
  smoke_04: () => loader.load(T.smoke_04),
  smoke_05: () => loader.load(T.smoke_05),
  smoke_06: () => loader.load(T.smoke_06),
  smoke_07: () => loader.load(T.smoke_07),
  smoke_08: () => loader.load(T.smoke_08),
  smoke_09: () => loader.load(T.smoke_09),
  smoke_10: () => loader.load(T.smoke_10),
  spark_01: () => loader.load(T.spark_01),
  spark_02: () => loader.load(T.spark_02),
  spark_03: () => loader.load(T.spark_03),
  spark_04: () => loader.load(T.spark_04),
  spark_05: () => loader.load(T.spark_05),
  spark_06: () => loader.load(T.spark_06),
  spark_07: () => loader.load(T.spark_07),
  star_01: () => loader.load(T.star_01),
  star_02: () => loader.load(T.star_02),
  star_03: () => loader.load(T.star_03),
  star_04: () => loader.load(T.star_04),
  star_05: () => loader.load(T.star_05),
  star_06: () => loader.load(T.star_06),
  star_07: () => loader.load(T.star_07),
  star_08: () => loader.load(T.star_08),
  star_09: () => loader.load(T.star_09),
  symbol_01: () => loader.load(T.symbol_01),
  symbol_02: () => loader.load(T.symbol_02),
  trace_01: () => loader.load(T.trace_01),
  trace_02: () => loader.load(T.trace_02),
  trace_03: () => loader.load(T.trace_03),
  trace_04: () => loader.load(T.trace_04),
  trace_05: () => loader.load(T.trace_05),
  trace_06: () => loader.load(T.trace_06),
  trace_07: () => loader.load(T.trace_07),
  twirl_01: () => loader.load(T.twirl_01),
  twirl_02: () => loader.load(T.twirl_02),
  twirl_03: () => loader.load(T.twirl_03),
  window_01: () => loader.load(T.window_01),
  window_02: () => loader.load(T.window_02),
  window_03: () => loader.load(T.window_03),
};
