<script>
  import BaseSvg from "~/ui/lib/BaseSvg.svelte";
</script>

<BaseSvg viewBox="0 0 1735 1735" {...$$props}>
  <g transform="matrix(1,0,0,1,-482.87,-482.87)">
    <g id="laughing">
      <g>
        <circle cx="1350" cy="1350" r="726.28" style="fill:rgb(255,179,0);" />
        <g transform="matrix(1.02784,0,0,1.39567,547.876,-352.349)">
          <ellipse
            cx="780.396"
            cy="1060.15"
            rx="569.604"
            ry="333.747"
            style="fill:url(#_Linear1);"
          />
        </g>
        <g
          transform="matrix(-0.959099,-1.17456e-16,1.59489e-16,-1.30233,2098.48,2984.72)"
        >
          <ellipse
            cx="780.396"
            cy="1060.15"
            rx="569.604"
            ry="333.747"
            style="fill:url(#_Linear2);"
          />
        </g>
      </g>
      <path
        d="M1350,1398.77C1616.47,1398.77 1759.35,1379.61 1800.57,1428.78C1827.69,1461.13 1758.23,1873.85 1350,1873.85C941.766,1873.85 869.934,1458.1 899.052,1427.54C945.531,1378.75 1083.53,1398.77 1350,1398.77Z"
        style="fill:rgb(50,22,3);"
      />
      <path
        d="M959.226,1562.99L1745.85,1562.99C1745.85,1562.99 1776.83,1471.61 1757.13,1453.73C1737.44,1435.86 1654.99,1436.59 1350,1436.59C1045.01,1436.59 959.772,1433.15 941.914,1452.53C925.79,1470.03 959.226,1562.99 959.226,1562.99Z"
        style="fill:white;"
      />
      <path
        d="M1214.07,1795.24C1214.07,1795.24 1282.69,1820.65 1350,1819.44C1417.31,1818.23 1491.53,1795.81 1491.53,1795.81C1491.53,1795.81 1466.23,1716.98 1350.6,1716.98C1234.98,1716.98 1214.07,1795.24 1214.07,1795.24Z"
        style="fill:rgb(219,117,107);"
      />
      <g transform="matrix(-1,0,0,1,2700.76,-194.569)">
        <path
          d="M1656.54,1225.95C1789.44,1220.03 1825.59,1353.77 1818.17,1358.71C1803.61,1368.4 1753.35,1305.95 1650.51,1306.33C1547.66,1306.7 1482.26,1389.21 1481.28,1375.81C1479.95,1357.66 1511.33,1232.43 1656.54,1225.95Z"
          style="fill:rgb(50,22,3);"
        />
      </g>
      <g transform="matrix(1,0,0,1,-13.34,-194.569)">
        <path
          d="M1656.54,1225.95C1789.44,1220.03 1825.59,1353.77 1818.17,1358.71C1803.61,1368.4 1753.35,1305.95 1650.51,1306.33C1547.66,1306.7 1482.26,1389.21 1481.28,1375.81C1479.95,1357.66 1511.33,1232.43 1656.54,1225.95Z"
          style="fill:rgb(50,22,3);"
        />
      </g>
    </g>
    <g transform="matrix(1,0,0,1,39.0684,-144.536)">
      <rect
        x="443.801"
        y="627.406"
        width="1734.26"
        height="1734.26"
        style="fill:none;"
      />
    </g>
  </g>
  <defs>
    <linearGradient
      id="_Linear1"
      x1="0"
      y1="0"
      x2="1"
      y2="0"
      gradientUnits="userSpaceOnUse"
      gradientTransform="matrix(2.56752e-13,667.493,-721.113,1.50438e-13,780.396,726.403)"
      ><stop offset="0" style="stop-color:white;stop-opacity:0.5" /><stop
        offset="1"
        style="stop-color:rgb(255,179,0);stop-opacity:0"
      /></linearGradient
    >
    <linearGradient
      id="_Linear2"
      x1="0"
      y1="0"
      x2="1"
      y2="0"
      gradientUnits="userSpaceOnUse"
      gradientTransform="matrix(2.56752e-13,667.493,-721.113,1.50438e-13,780.396,726.403)"
      ><stop offset="0" style="stop-color:white;stop-opacity:0.25" /><stop
        offset="0.51"
        style="stop-color:rgb(255,198,65);stop-opacity:0.06"
      /><stop
        offset="1"
        style="stop-color:rgb(255,179,0);stop-opacity:0"
      /></linearGradient
    >
  </defs>
</BaseSvg>
