<script>
  import BaseSvg from "~/ui/lib/BaseSvg.svelte";
</script>

<BaseSvg viewBox="0 0 1735 1735" {...$$props}>
  <g transform="matrix(1,0,0,1,-482.87,-482.87)">
    <g id="heart">
      <g transform="matrix(1,0,0,1,-2.9651e-05,60.6174)">
        <path
          d="M653.293,1098.04C656.184,895.741 821.327,732.384 1024.32,732.384C1164.69,732.384 1286.96,810.493 1350,925.577C1413.04,810.493 1535.31,732.384 1675.68,732.384C1878.67,732.384 2043.82,895.741 2046.71,1098.04C2046.75,1099.64 2046.76,1101.24 2046.75,1102.87L2046.75,1103.45C2046.75,1147.44 2039.07,1189.66 2024.99,1228.84C1907.04,1607.42 1350,2015.66 1350,2015.66C1350,2015.66 792.96,1607.42 675.009,1228.84C660.926,1189.66 653.254,1147.44 653.254,1103.45L653.25,1102.87C653.241,1101.23 653.255,1099.61 653.293,1098.04Z"
          style="fill:rgb(191,27,1);"
        />
      </g>
      <path
        d="M697.031,1159.72L697.036,1159.5C699.588,980.839 845.265,836.751 1024.32,836.751C1148.16,836.751 1227.53,907.151 1311.63,996.758L1350,1038.7L1388.37,996.758C1468.41,907.781 1551.84,836.751 1675.68,836.751C1854.74,836.751 2000.41,980.839 2002.96,1159.28L2002.96,1159.5C2003,1160.87 2003.01,1162.03 2003,1163.21C2003,1163.21 2003,1163.91 2003,1163.91C2003,1202.87 1996.24,1240.1 1983.82,1274.66L1983.5,1275.55C1937.78,1422.29 1823.81,1572.13 1704.44,1700.13C1561.3,1853.62 1409.36,1975.58 1350,2021.39C1290.64,1975.58 1138.7,1853.62 995.556,1700.13C876.192,1572.13 762.218,1422.29 716.778,1276.45L716.498,1275.55C703.76,1240.1 697.004,1202.87 697.004,1164.07L696.999,1163.21C696.992,1162.03 697.003,1160.86 697.031,1159.72Z"
        style="fill:url(#_Linear1);"
      />
    </g>
    <g transform="matrix(1,0,0,1,39.0684,-144.536)">
      <rect
        x="443.801"
        y="627.406"
        width="1734.26"
        height="1734.26"
        style="fill:none;"
      />
    </g>
  </g>
  <defs>
    <linearGradient
      id="_Linear1"
      x1="0"
      y1="0"
      x2="1"
      y2="0"
      gradientUnits="userSpaceOnUse"
      gradientTransform="matrix(3.14274e-14,513.249,-465.554,2.8507e-14,1350,836.751)"
      ><stop offset="0" style="stop-color:white;stop-opacity:1" /><stop
        offset="0.55"
        style="stop-color:rgb(254,120,100);stop-opacity:1"
      /><stop
        offset="1"
        style="stop-color:rgb(254,66,37);stop-opacity:1"
      /></linearGradient
    >
  </defs>
</BaseSvg>
