<script>
  import BaseSvg from "~/ui/lib/BaseSvg.svelte";
</script>

<BaseSvg viewBox="0 0 1735 1735" {...$$props}>
  <g transform="matrix(1,0,0,1,-482.87,-482.87)">
    <g id="sad">
      <g>
        <circle cx="1350" cy="1350" r="726.28" style="fill:rgb(255,179,0);" />
        <g transform="matrix(1.02784,0,0,1.39567,547.876,-352.349)">
          <ellipse
            cx="780.396"
            cy="1060.15"
            rx="569.604"
            ry="333.747"
            style="fill:url(#_Linear1);"
          />
        </g>
        <g
          transform="matrix(-0.959099,-1.17456e-16,1.59489e-16,-1.30233,2098.48,2984.72)"
        >
          <ellipse
            cx="780.396"
            cy="1060.15"
            rx="569.604"
            ry="333.747"
            style="fill:url(#_Linear2);"
          />
        </g>
      </g>
      <circle cx="1350" cy="1350" r="726.28" style="fill:none;" />
      <clipPath id="_clip3">
        <circle cx="1350" cy="1350" r="726.28" />
      </clipPath>
      <g clip-path="url(#_clip3)">
        <g>
          <rect
            x="1566.69"
            y="1301.52"
            width="177.026"
            height="756.52"
            style="fill:url(#_Linear4);"
          />
          <path
            d="M1731.05,973.617C1880.46,1042.42 1871.06,1151.35 1871.06,1151.35C1871.06,1151.35 1845.16,1072.26 1726.41,1016.62C1656.06,983.668 1576.43,987.702 1567.5,976.737C1557.69,964.68 1638.35,930.927 1731.05,973.617Z"
            style="fill:rgb(46,20,3);"
          />
          <path
            d="M1656.54,1225.95C1789.44,1220.03 1825.59,1353.77 1818.17,1358.71C1803.61,1368.4 1753.35,1305.95 1650.51,1306.33C1547.66,1306.7 1482.26,1389.21 1481.28,1375.81C1479.95,1357.66 1511.33,1232.43 1656.54,1225.95Z"
            style="fill:rgb(46,20,3);"
          />
        </g>
        <g transform="matrix(-1,0,0,1,2700.76,0)">
          <rect
            x="1566.69"
            y="1301.52"
            width="177.026"
            height="756.52"
            style="fill:url(#_Linear5);"
          />
          <path
            d="M1731.05,973.617C1880.46,1042.42 1871.06,1151.35 1871.06,1151.35C1871.06,1151.35 1845.16,1072.26 1726.41,1016.62C1656.06,983.668 1576.43,987.702 1567.5,976.737C1557.69,964.68 1638.35,930.927 1731.05,973.617Z"
            style="fill:rgb(46,20,3);"
          />
          <path
            d="M1656.54,1225.95C1789.44,1220.03 1825.59,1353.77 1818.17,1358.71C1803.61,1368.4 1753.35,1305.95 1650.51,1306.33C1547.66,1306.7 1482.26,1389.21 1481.28,1375.81C1479.95,1357.66 1511.33,1232.43 1656.54,1225.95Z"
            style="fill:rgb(46,20,3);"
          />
        </g>
        <g>
          <g transform="matrix(1.01939,0,0,1,-29.2687,0)">
            <ellipse
              cx="1352.28"
              cy="1717.93"
              rx="157.154"
              ry="191.977"
              style="fill:rgb(46,20,3);"
            />
          </g>
          <path
            d="M1214.21,1687.84C1224.85,1608.54 1281.29,1547.96 1349.23,1547.96C1417.18,1547.96 1473.62,1608.54 1484.26,1687.84L1214.21,1687.84Z"
            style="fill:white;"
          />
        </g>
      </g>
    </g>
    <g transform="matrix(1,0,0,1,39.0684,-144.536)">
      <rect
        x="443.801"
        y="627.406"
        width="1734.26"
        height="1734.26"
        style="fill:none;"
      />
    </g>
  </g>
  <defs>
    <linearGradient
      id="_Linear1"
      x1="0"
      y1="0"
      x2="1"
      y2="0"
      gradientUnits="userSpaceOnUse"
      gradientTransform="matrix(2.56752e-13,667.493,-721.113,1.50438e-13,780.396,726.403)"
      ><stop offset="0" style="stop-color:white;stop-opacity:0.5" /><stop
        offset="1"
        style="stop-color:rgb(255,179,0);stop-opacity:0"
      /></linearGradient
    >
    <linearGradient
      id="_Linear2"
      x1="0"
      y1="0"
      x2="1"
      y2="0"
      gradientUnits="userSpaceOnUse"
      gradientTransform="matrix(2.56752e-13,667.493,-721.113,1.50438e-13,780.396,726.403)"
      ><stop offset="0" style="stop-color:white;stop-opacity:0.25" /><stop
        offset="0.51"
        style="stop-color:rgb(255,198,65);stop-opacity:0.06"
      /><stop
        offset="1"
        style="stop-color:rgb(255,179,0);stop-opacity:0"
      /></linearGradient
    >
    <linearGradient
      id="_Linear4"
      x1="0"
      y1="0"
      x2="1"
      y2="0"
      gradientUnits="userSpaceOnUse"
      gradientTransform="matrix(177.026,0,0,756.52,1566.69,1679.78)"
      ><stop
        offset="0"
        style="stop-color:rgb(89,192,251);stop-opacity:1"
      /><stop
        offset="0.5"
        style="stop-color:rgb(159,229,248);stop-opacity:1"
      /><stop
        offset="1"
        style="stop-color:rgb(89,192,251);stop-opacity:1"
      /></linearGradient
    >
    <linearGradient
      id="_Linear5"
      x1="0"
      y1="0"
      x2="1"
      y2="0"
      gradientUnits="userSpaceOnUse"
      gradientTransform="matrix(177.026,0,0,756.52,1566.69,1679.78)"
      ><stop
        offset="0"
        style="stop-color:rgb(89,192,251);stop-opacity:1"
      /><stop
        offset="0.5"
        style="stop-color:rgb(159,229,248);stop-opacity:1"
      /><stop
        offset="1"
        style="stop-color:rgb(89,192,251);stop-opacity:1"
      /></linearGradient
    >
  </defs>
</BaseSvg>
